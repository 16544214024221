import { jwtEnvAxios } from ".";

export function getK8ServicesApi() {
  return jwtEnvAxios("k8").get(`/service/details`);
}

export function UpdateK8ServiceApi(id: string, data: any) {
  return jwtEnvAxios("k8").put(`/service/${id}`, data);
}

export function createK8ServiceApi(data: any) {
  return jwtEnvAxios("k8").post(`/service`, data);
}

export function deleteK8ServiceApi(id: string) {
  return jwtEnvAxios("k8").delete(`/service/${id}`);
}

export function getK8ServiceApi(id: string) {
  return jwtEnvAxios("k8").get(`/service/${id}`);
}

export function getK8LogsApi(id: string) {
  return jwtEnvAxios("k8").get(`/service/${id}/logs`);
}

export function getK8NodePoolsApi(id: string) {
  return jwtEnvAxios("k8").get(`/service/${id}/nodepool`);
}

export function getK8IpRestrictionsApi(id: string) {
  return jwtEnvAxios("k8").get(`/service/${id}/ipRestrictions`);
}

export function createK8IpRestrictionsApi(id: string, data: Array<string>) {
  return jwtEnvAxios("k8").put(`/service/${id}/ipRestrictions`, { ips: data });
}

export function K8ResetClusterApi(id: string) {
  return jwtEnvAxios("k8").post(`/service/${id}/reset`);
}
