import { Button, Input, Modal } from "djuno-design";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import { useCallback, useEffect } from "react";
import {
  createK8IpRestrictionsAsync,
  getK8IpRestrictionsAsync,
  handleToggleK8IpRestrictionEditor,
  selectK8IpRestrictionEditor,
  selectK8IpRestrictions,
  selectK8IpRestrictionsActionLoading,
  selectK8IpRestrictionsLoading,
  selectK8SelectedIpRestriction,
  selectK8Service,
} from "../../../store/kubernetes/k8ServiceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { K8IpRestrictionSchema } from "../../../utils/validations";

const K8IpRestrictionEditorModal = () => {
  const k8Service = useAppSelector(selectK8Service);
  const isOpen = useAppSelector(selectK8IpRestrictionEditor);
  const selectedIpRestriction = useAppSelector(selectK8SelectedIpRestriction);
  const updateLoading = useAppSelector(selectK8IpRestrictionsActionLoading);

  const ips = useAppSelector(selectK8IpRestrictions);
  const ipsLoading = useAppSelector(selectK8IpRestrictionsLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(K8IpRestrictionSchema) });

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleK8IpRestrictionEditor({ status: false }));
  }, [dispatch]);

  const handleUpdateIpRestrictions = useCallback(
    (data: any) => {
      if (k8Service && !updateLoading && !ipsLoading) {
        dispatch(
          createK8IpRestrictionsAsync({
            id: k8Service.id,
            ips: [...ips, data.ip],
          })
        ).then((action) => {
          if (action.type === "k8-service/ip-restrictions/create/fulfilled") {
            handleOnClose();
            dispatch(
              getK8IpRestrictionsAsync({
                id: k8Service.id,
              })
            );
          }
        });
      }
    },
    [dispatch, handleOnClose, ips, ipsLoading, k8Service, updateLoading]
  );

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      title="Add Ip Restriction"
      contentClassName="!max-w-xl"
      onClose={handleOnClose}
    >
      <form
        onSubmit={handleSubmit(handleUpdateIpRestrictions)}
        className="mt-5"
      >
        <Input
          {...register("ip")}
          error={errors.ip?.message}
          label="IPv4 client"
          required
        />
        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={handleOnClose} uiType="light">
            Cancel
          </Button>
          <Button
            loading={updateLoading || ipsLoading}
            uiType="primary"
            disabled={!k8Service || ipsLoading}
            type="submit"
          >
            {selectedIpRestriction ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default K8IpRestrictionEditorModal;
