import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";
import {
  createK8IpRestrictionsAsync,
  getK8IpRestrictionsAsync,
  handleToggleK8IpRestrictionEditor,
  selectK8IpRestrictions,
  selectK8IpRestrictionsActionLoading,
  selectK8IpRestrictionsLoading,
  selectK8Service,
} from "../../../store/kubernetes/k8ServiceSlice";
import K8IpRestrictionEditorModal from "./K8IpRestrictionEditorModal";
import HighlighterText from "../../general/HighlighterText";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { useSearch } from "../../../providers/SearchProvider";
import { DeleteModal } from "../../modals/QuestionModal";

const K8IpRestrictionsTab = () => {
  const k8Service = useAppSelector(selectK8Service);

  const ips = useAppSelector(selectK8IpRestrictions);
  const ipsLoading = useAppSelector(selectK8IpRestrictionsLoading);
  const [filteredIps, setFilteredIps] = useState<string[]>([]);

  const ipsActionLoading = useAppSelector(selectK8IpRestrictionsActionLoading);
  const [deleteIp, setDeleteIp] = useState<string | null>(null);

  const { value: searchValue } = useSearch();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (k8Service) {
      dispatch(getK8IpRestrictionsAsync({ id: k8Service.id }));
    }
  }, [dispatch, k8Service]);

  useEffect(() => {
    const lookedUpFiles = ips?.filter((ip) =>
      ip?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredIps(lookedUpFiles);
  }, [dispatch, ips, searchValue]);

  return (
    <>
      <Flex items="center" justify="between" className="webapps-header">
        <Typography.Title level={5}>
          Manage access to the APIServer
        </Typography.Title>
        <div className="flex ">
          <div className="flex create-webapp-button">
            <Button
              onClick={() => {
                dispatch(handleToggleK8IpRestrictionEditor({ status: true }));
              }}
              uiType="primary"
              className="group"
            >
              Add IP
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>
      </Flex>

      <div className="mt-5 w-full database-table">
        <Typography.Text size="sm">
          Choose the IPv4 ranges that can access your cluster’s APIServer. If
          there is no IP, no restriction is applied
        </Typography.Text>
        <SimpleTable
          loading={ipsLoading}
          containerClassName="min-h-[240px] mt-5"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Authorised clients" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredIps.map((ip, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD className="cursor-pointer">
                  <Flex>
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={ip}
                      className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                    />
                  </Flex>
                </SimpleTable.TD>

                <SimpleTable.TD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!w-48"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <DetailsIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(
                                handleToggleK8IpRestrictionEditor({
                                  status: true,
                                  ipRestriction: ip,
                                })
                              );
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteIp(ip);
                            },
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {ips.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={2} className="!border-0">
                  <EmptyState text="You have not created any ip yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
        <K8IpRestrictionEditorModal />
        <DeleteModal
          title="Delete Ip"
          isOpen={deleteIp !== null}
          onClose={() => setDeleteIp(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          loading={ipsActionLoading || ipsLoading || ipsActionLoading}
          onConfirm={() => {
            deleteIp &&
              k8Service &&
              ips &&
              dispatch(
                createK8IpRestrictionsAsync({
                  id: k8Service.id,
                  ips: [...ips.filter((ip) => ip !== deleteIp)],
                })
              ).then((action) => {
                if (
                  action.type === "k8-service/ip-restrictions/create/fulfilled"
                ) {
                  dispatch(getK8IpRestrictionsAsync({ id: k8Service.id }));
                  setDeleteIp(null);
                }
              });
          }}
        />
      </div>
    </>
  );
};

export default K8IpRestrictionsTab;
