import {
  Button,
  Card,
  cn,
  Dropdown,
  Flex,
  Loading,
  SecureCopy,
  Tag,
  Tooltip,
  Typography,
} from "djuno-design";
import { NotExist } from "../../layouts/NotData";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as BootIcon } from "./../../../assets/icons/square-3stack.svg";
import { ReactComponent as StopIcon } from "./../../../assets/icons/stop.svg";
import { ReactComponent as RebootIcon } from "./../../../assets/icons/arrow-path-rounded-square.svg";
import { ReactComponent as PuseIcon } from "./../../../assets/icons/puse-circle.svg";
import { ReactComponent as ExitIcon } from "./../../../assets/icons/arrow-left-start-on-rectangle.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/icons/question-mark-circle.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import QuestionModal from "../../modals/QuestionModal";
import {
  getK8ServiceAsync,
  selectK8Service,
  selectK8ServiceLoading,
} from "../../../store/kubernetes/k8ServiceSlice";
import { K8ServicesUrl } from "../../../utils/urls";
import CustomLink from "../../general/CustomLink";

const K8OverviewTab = () => {
  //   const [deleteInstance, setDeleteInstance] = useState<Instance | null>(null);

  const k8Service = useAppSelector(selectK8Service);
  const loading = useAppSelector(selectK8ServiceLoading);

  console.log("k8Service", k8Service);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getK8ServiceAsync({ id: id }));
  //   }
  // }, [dispatch, id]);

  const OverviewCardSetting = () => {
    if (!k8Service) return null;
    return (
      <div className="flex gap-2">
        <Button
          uiSize="medium"
          uiType="light"
          onClick={() =>
            dispatch(
              getK8ServiceAsync({
                id: k8Service.id,
              })
            )
          }
          className="group"
          tooltip={{ content: "Refresh" }}
        >
          <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
        </Button>

        <Dropdown
          itemsClassName="!p-0 !w-48"
          anchor="bottom end"
          menu={[
            {
              key: "1",
              label: "Rename",
              //   onClick: () => navigate(InstancesVolumesUrl),
            },
            {
              key: "2",
              label: "Reset your cluster",
              //   onClick: () => navigate(InstancesVolumesUrl),
            },
            {
              key: "3",
              label: "Create a node pool",
              //   onClick: () => navigate(InstancesVolumesUrl),
            },
            {
              key: "4",
              label: "Reset kubeconfig",
              //   onClick: () => navigate(InstancesVolumesUrl),
            },
            {
              key: "5",
              label: "Change security policy",
              //   onClick: () => navigate(InstancesVolumesUrl),
            },
            {
              type: "divider",
            },
            {
              key: "end",
              label: "Delete",
              //   onClick: () => dispatch(handleToggleInstanceVolumeModal(true)),
            },
          ]}
        >
          <div className="p-1 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
          </div>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      {k8Service && !loading && (
        <>
          <div className="mt-5">
            <Card title="Network" setting={<OverviewCardSetting />}>
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Attached network:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">Public</Typography.Text>
                </Flex>
              </div>
            </Card>
          </div>
          <div className="mt-5">
            <Card title="Cluster information">
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <div className="mb-4 md:mb-0 w-full">
                    <Typography.Text strong className="!text-sm">
                      ID
                    </Typography.Text>

                    <SecureCopy type="copy" text={k8Service.id} />
                  </div>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Name:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {k8Service?.name}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Status:
                  </Typography.Text>
                  <Tag
                    className="text-xs inline-block"
                    color={k8Service.status === "READY" ? "success" : "warning"}
                  >
                    {k8Service.status}
                  </Tag>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Minor version of Kubernetes:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {k8Service.version}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text
                    strong
                    className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2 "
                  >
                    Use of ETCD quota
                  </Typography.Text>
                  <Tooltip
                    content="The ETCD quota represents the space allocated to the ETCD database in your managed cluster."
                    className="!text-sm"
                  >
                    <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100  !mt-3" />
                  </Tooltip>
                </Flex>

                <div className="flex flex-col gap-1">
                  <Typography.Text strong className="!text-sm">
                    Admission Plugins:
                  </Typography.Text>

                  <div className="flex justify-between items-center">
                    <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap">
                      Plugin Always Pull Images
                      <Tooltip content="Each new pod has to download the required images every time.">
                        <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
                      </Tooltip>
                    </Typography.Text>
                    {k8Service.customization.apiServer.admissionPlugins.enabled.includes(
                      "AlwaysPullImages"
                    ) ? (
                      <Tag color="success" className="!h-6">
                        Enabled
                      </Tag>
                    ) : (
                      <Tag color="warning" className="!h-6">
                        Disabled
                      </Tag>
                    )}
                  </div>

                  <div className="flex justify-between items-center">
                    <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap mb-2">
                      Plugin Node Restriction
                      <Tooltip content="The NodeRestriction admission controller plugin restricts what Node and Pod objects a Kubelet can modify. When this admission controller is active, Kubelets are limited to modifying their own API Node object and only API Pod objects that are linked to their node.">
                        <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
                      </Tooltip>
                    </Typography.Text>
                    {k8Service.customization.apiServer.admissionPlugins.enabled.includes(
                      "NodeRestriction"
                    ) ? (
                      <Tag color="success" className="!h-6">
                        Enabled
                      </Tag>
                    ) : (
                      <Tag color="warning" className="!h-6">
                        Disabled
                      </Tag>
                    )}
                  </div>

                  <Button>Enable/disable plugins</Button>
                </div>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Region:
                  </Typography.Text>
                  <Typography.Text className="!text-sm !inline-block">
                    {k8Service.region}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <div className="mb-4 md:mb-0 w-full">
                    <Typography.Text strong className="!text-sm">
                      Kubernetes node URLs for your cluster
                    </Typography.Text>

                    <SecureCopy type="copy" text={k8Service.nodesUrl} />
                  </div>
                </Flex>
              </div>
            </Card>
          </div>
          <div className="mt-5">
            <Card title="Access and security">
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <div className="mb-4 md:mb-0 w-full">
                    <Typography.Text strong className="!text-sm">
                      Kubernetes API URL for your cluster
                    </Typography.Text>

                    <SecureCopy type="copy" text={k8Service.url} />
                  </div>
                </Flex>

                <div className="flex flex-col">
                  <Typography.Text strong className="!text-sm">
                    Access limit to APIServer:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    No limit
                  </Typography.Text>

                  <Typography.Text className="text-sm flex items-center gap-1 mt-1">
                    <CustomLink
                      className={cn(
                        "text-sm cursor-pointer text-sky-500 hover:text-sky-600"
                      )}
                      to=""
                    >
                      Modify limitations
                    </CustomLink>
                  </Typography.Text>
                </div>

                <div className="flex flex-col gap-1">
                  <Flex items="center" className="gap-1">
                    <Typography.Text
                      strong
                      className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2 "
                    >
                      Kubeconfig file
                    </Typography.Text>
                    <Tooltip
                      content="The kubeconfig file contains the data required by the kubectl command-line tool to communicate with a cluster's API."
                      className="!text-sm"
                    >
                      {/* link    https://kubernetes.io/docs/concepts/configuration/organize-cluster-access-kubeconfig/ */}
                      <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100  !mt-3" />
                    </Tooltip>
                  </Flex>

                  <Typography.Text className="text-sm flex items-center gap-1 mt-1">
                    <CustomLink
                      className={cn(
                        "text-sm cursor-pointer text-sky-500 hover:text-sky-600"
                      )}
                      to=""
                    >
                      kobeconfig
                    </CustomLink>
                  </Typography.Text>
                </div>

                <Flex items="center" justify="between" className="gap-1">
                  <Flex items="center" className="gap-1">
                    <Typography.Text strong className="!text-sm">
                      OIDC provider:
                    </Typography.Text>

                    <Typography.Text className="!text-sm">
                      No OIDC provider configured
                    </Typography.Text>
                  </Flex>
                  <div className="flex border border-gray-300 rounded-md">
                    <Dropdown
                      itemsClassName="!p-0 !w-60"
                      anchor="bottom end"
                      menu={[
                        {
                          key: "1",
                          label: "Configure an OIDC provider",
                          // onClick: () => navigate(InstancesVolumesUrl),
                          // disabled: loading,
                        },
                        {
                          key: "1",
                          label: "Edit OIDC provider",
                          // onClick: () => navigate(InstancesVolumesUrl),
                          // disabled: loading,
                        },

                        {
                          key: "Delete the OIDC provider",
                          label: "Attach a volume",
                          // onClick: () =>
                          //   dispatch(handleToggleInstanceVolumeModal(true)),
                          // disabled: loading,
                        },
                      ]}
                    >
                      <div className="p-1 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                        <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                      </div>
                    </Dropdown>
                  </div>
                </Flex>
                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Security updates:
                  </Typography.Text>
                  <Typography.Text className="!text-sm !inline-block">
                    Maximum security
                  </Typography.Text>
                </Flex>
              </div>
            </Card>
          </div>
        </>
      )}

      {/* empty service */}
      {k8Service === null && !loading && (
        <Flex items="center" justify="center" className="h-full px-4">
          <NotExist
            url={() => navigate(K8ServicesUrl)}
            text="The cluster does not exist"
          />
        </Flex>
      )}

      {loading && (
        <Flex items="center" justify="center" className="!min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}
    </>
  );
};

export default K8OverviewTab;
