import { Helmet } from "react-helmet";
import {
  getDatabaseServiceAsync,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import { DataServiceGeneralInformationUrl } from "../../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import {
  AdditionalStorageInput,
  dbsPeriodItems,
  filterAvailabilitiesByRegion,
  getNodeTypesFromAvailability,
} from "../ServiceCreateComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceUpdateStorageSchema } from "../../../utils/validations";
import {
  DBSAddon,
  DBSAvailability,
  DBSCategory,
  DBSNodeType,
} from "../../../types/database";
import {
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDBSCatalogAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDBSCatalog,
  selectDBSCatalogLoading,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import RadioGrouper from "../../general/RadioGrouper";
import { Button, Typography } from "djuno-design";

const DBSUpgradeStorageTab: React.FC<{ category: DBSCategory }> = ({
  category,
}) => {
  const service = useAppSelector(selectDatabaseService);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DatabaseServiceUpdateStorageSchema()),
    reValidateMode: "onChange",
  });

  const [planAvailability, setPlanAvailability] = useState<
    Array<DBSAvailability>
  >([]);
  // const [nodeTypes, setNodeTypes] = useState<Array<DBSNodeType>>([]);
  const [selectedDBSNodeType, setSelectedDBSNodeType] = useState<
    DBSNodeType | undefined
  >();

  const [addons, setAddons] = useState<Array<DBSAddon>>([]);
  const [period, setPeriod] = useState<"month" | "hour">("hour");

  const additionalStorage = watch("AdditionalStorage");

  // create additional storage plan code
  const additionnalStoragePlanCode = useMemo(() => {
    return `databases.${service?.engine}-${service?.plan}-additionnal-storage-gb.${period}.consumption`;
  }, [period, service]);

  // find additional-storage addon
  const additionalStoragePlanAddon = useMemo(() => {
    return addons.find((a) => a.planCode === additionnalStoragePlanCode);
  }, [addons, additionnalStoragePlanCode]);

  useEffect(() => {
    if (capabilities === undefined) dispatch(getDBSCapabilitiesAsync());
  }, [dispatch, capabilities]);

  useEffect(() => {
    if (availability === undefined) dispatch(getDBSAvailabilityAsync());
  }, [dispatch, availability]);

  useEffect(() => {
    if (catalog === undefined) dispatch(getDBSCatalogAsync());
  }, [dispatch, catalog]);

  const defaultStorage = useMemo(() => {
    return (
      selectedDBSNodeType?.availability.specifications.storage?.minimum.value ||
      0
    );
  }, [selectedDBSNodeType?.availability.specifications.storage?.minimum.value]);

  // process plansAvailability after changing -> selected engine, selected version
  useEffect(() => {
    if (service) {
      const plansAvailability = availability?.filter(
        (a) =>
          a.lifecycle.status === "STABLE" &&
          a.engine === service.engine &&
          a.network === "public" &&
          a.version === service.version &&
          a.plan === service.plan
      );
      setPlanAvailability(plansAvailability || []);
      setValue(
        "AdditionalStorage",
        service.storage.size.value - defaultStorage
      );
    }
  }, [availability, defaultStorage, service, setValue]);

  // make nodes by selected planGroup and selected region
  useEffect(() => {
    if (capabilities && service) {
      const availability = filterAvailabilitiesByRegion(
        planAvailability,
        service.nodes[0].region
      );
      const nodeTypes = getNodeTypesFromAvailability(
        availability,
        capabilities,
        service.version
      );
      if (nodeTypes && nodeTypes.length > 0) {
        const nodeType = nodeTypes.find(
          (nt) => nt.flavor.name === service.flavor
        );
        if (nodeType) {
          setSelectedDBSNodeType(nodeType);
        } else {
          setSelectedDBSNodeType(nodeType);
        }
      }
    }
  }, [capabilities, planAvailability, service, setValue]);

  //filter addons by selected engine and period
  useEffect(() => {
    if (catalog && service) {
      const planCode = `databases.${service.engine}`;
      const filteredAddons = catalog.addons
        .filter((addon) => addon.planCode.includes(planCode))
        .filter((addon) => addon.planCode.includes(period));
      setAddons(filteredAddons);
    }
  }, [catalog, period, service]);

  const handleSubmitForm = () => {
    if (service && additionalStorage && !actionLoading) {
      const updateData = {
        disk: { size: additionalStorage + defaultStorage },
      };
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: updateData,
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          dispatch(
            getDatabaseServiceAsync({ engine: service.engine, id: service.id })
          );

          navigate(
            DataServiceGeneralInformationUrl(service.engine, service.id)
          );
        }
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Upgrade node </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="p-4">
        {service && (
          <div className="flex items-center justify-between">
            <div
              className="group px-1 items-center flex transition-all duration-150 cursor-pointer gap-x-0.5"
              onClick={() =>
                navigate(
                  DataServiceGeneralInformationUrl(service.engine, service.id)
                )
              }
            >
              <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />
              <Typography.Text className="!text-sm !text-primary-500 ">
                Back to “Information”
              </Typography.Text>
            </div>
            <RadioGrouper
              items={dbsPeriodItems}
              selected={dbsPeriodItems.find((i) => i.value === period)}
              setSelected={(item) => setPeriod(item.value)}
              dir="row"
            />
          </div>
        )}

        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full">
            {service && selectedDBSNodeType && (
              <AdditionalStorageInput
                control={control}
                nodeType={selectedDBSNodeType}
                period={period}
                nodeCount={service.nodes.length}
                addon={additionalStoragePlanAddon}
                errorMessage={errors.AdditionalStorage?.message}
              />
            )}
          </div>
          <div className="flex items-center gap-3 mt-5">
            <Button
              onClick={() => {
                if (service)
                  navigate(
                    DataServiceGeneralInformationUrl(service.engine, service.id)
                  );
              }}
            >
              Cancel
            </Button>
            <Button
              loading={
                actionLoading ||
                capabilitiesLoading ||
                availabilityLoading ||
                catalogLoading
              }
              uiType="primary"
              type="submit"
            >
              Validate
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DBSUpgradeStorageTab;
