import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  K8GeneralInformationUrl,
  K8NodePoolsUrl,
  K8ApiServerAccessUrl,
  K8LogsUrl,
  K8ServicesUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Helmet } from "react-helmet";
import { Skeleton, TabOptions, Tabs, Typography } from "djuno-design";
import { ReactComponent as UsersIcon } from "./../../assets/icons/users.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as NoticeIcon } from "./../../assets/icons/logs/notice.svg";
import { ReactComponent as PoolsIcon } from "./../../assets/icons/archive-box2.svg";
import { K8RefreshStatus } from "../../store/kubernetes/k8ServicesSlice";
import {
  getK8ServiceAsync,
  handleClearK8ServiceSlice,
  selectK8Service,
  selectK8ServiceLoading,
} from "../../store/kubernetes/k8ServiceSlice";

const generateK8ServiceRoutes = (id: string = ""): TabOptions => {
  return [
    {
      label: (
        <div className="flex items-center gap-1">
          <NoticeIcon className="w-3" />
          Service
        </div>
      ),
      url: K8GeneralInformationUrl(id),
      testId: "1th-tab",
    },

    {
      label: (
        <div className="flex items-center gap-1 metrics">
          <PoolsIcon className="w-3" />
          Node pools
        </div>
      ),
      url: K8NodePoolsUrl(id),
      testId: "2th-tab",
    },
    {
      label: (
        <div className="flex items-center gap-1 security">
          <UsersIcon className="w-3" />
          APIServer access
        </div>
      ),
      url: K8ApiServerAccessUrl(id),
      testId: "3th-tab",
    },
    {
      label: (
        <div className="flex items-center gap-1 security">
          <BeatIcon className="w-3" />
          Logs
        </div>
      ),
      url: K8LogsUrl(id),
      testId: "4th-tab",
    },
  ];
};

const K8ServicePage = () => {
  const { kubernetesId } = useParams();

  const service = useAppSelector(selectK8Service);
  const loading = useAppSelector(selectK8ServiceLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const K8ServicePageMenus = useMemo(() => {
    if (service) {
      return generateK8ServiceRoutes(service.id);
    }
    return [];
  }, [service]);

  useEffect(() => {
    if (kubernetesId) {
      dispatch(getK8ServiceAsync({ id: kubernetesId }));
    }

    return () => {
      dispatch(handleClearK8ServiceSlice());
    };
  }, [dispatch, kubernetesId]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && K8RefreshStatus.includes(service.status)) {
      interval = setInterval(() => {
        dispatch(
          getK8ServiceAsync({
            id: service.id,
            withoutLoading: true,
          })
        );
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {service?.name || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => {
                navigate(K8ServicesUrl);
              }}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {service && !loading && (
              <>
                <div className="flex flex-col">
                  <Typography.Title level={5} className="!mb-0">
                    {service.name}
                  </Typography.Title>
                </div>
              </>
            )}
            {loading && (
              <>
                <Skeleton
                  shape="rectangle"
                  style={{ width: 200, height: 30 }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="px-6 mt-5">
        <div className="h-11">
          <Tabs options={K8ServicePageMenus} />
        </div>
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default K8ServicePage;
