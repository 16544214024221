import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  useLocation,
} from "react-router-dom";
import { PropsWithChildren } from "react";
import { useAppSelector } from "./hooks";
import { selectIsAuthenticate } from "./store/auth/authSlice";

//layouts - general
import AuthLayout from "./components/layouts/AuthLayout";
import PanelLayout from "./components/layouts/PanelLayout";
import ErrorPage from "./pages/ِErrorPage";
import NotFoundPage from "./pages/NotFoundPage";
import EmailVerification from "./components/auth/EmailVerification";

//auth
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";

//ipfs
import IpfsPage, { IpfsPageMenus } from "./pages/ipfs/IpfsPage";
import {
  BlockApiOverviewUrl,
  RpcEndpointsUrl,
  MarketApiOverviewUrl,
  NftApiOverviewUrl,
  SignInUrl,
  WalletApiOverviewUrl,
  WorkflowsUrl,
  Web3AuthEndpointsUrl,
  DataServicesUrl,
  HomeUrl,
  SettingsProfileUrl,
} from "./utils/urls";

//settings
import SettingsPage, { SettingsPageMenus } from "./pages/settings/SettingsPage";
import AcceptInvitedTeamPage from "./pages/settings/AcceptInvitedTeamPage";
//rpc - endpoint
import RpcEndpointsPage, {
  RpcEndpontsPageMenus,
} from "./pages/rpc-endpoints/RpcEndpointsPage";
import RpcEndpointCreatePage from "./pages/rpc-endpoints/RpcEndpointCreatePage";
import RpcEndpointPage from "./pages/rpc-endpoints/RpcEndpointPage";
import RpcEndpointGetStartedTab from "./components/rpc-endpoints/get-started/RpcEndpointGetStartedTab";
import RpcEndpointMetricsTab from "./components/rpc-endpoints/metrics/RpcEndpointMetricsTab";
import RpcEndpointSecurityTab from "./components/rpc-endpoints/security/RpcEndpointSecyrityTab";

//s3
import S3Page, { S3PageMenus } from "./pages/s3/S3Page";
import S3BucketPage from "./pages/s3/S3BucketPage";
import S3BucketSettingPage from "./pages/s3/S3BucketSettingPage";
import S3BucketCreatePage from "./pages/s3/S3BucketCreatePage";
import S3EventAddPage from "./pages/s3/S3EventAddPage";
import S3SelectDestination from "./components/s3/events/S3SelectDestination";
import S3EventEditorPage from "./components/s3/events/S3EventEditorPage";
import S3SelectTierType from "./components/s3/tiers/S3SelectTierType";
import S3TierAddPage from "./pages/s3/S3TierAddPage";
import S3TierEditorPage from "./components/s3/tiers/S3TierEditorPage";

//block-events
import BlockEventsPage, {
  BlockEventsPageMenus,
} from "./pages/block-events/BlockEventsPage";
import BlockEventDestinationPage from "./pages/block-events/BlockEventDestinationPage";
import BlockEventCreatePage from "./pages/block-events/BlockEventCreatePage";
import BlockEventPage from "./pages/block-events/BlockEventPage";

//web3-auth
import Web3AuthEndpintsPage, {
  Web3AuthEndpontsPageMenus,
} from "./pages/web3-auth/Web3AuthEndpointsPage";
import Web3AuthEndpintPage from "./pages/web3-auth/Web3AuthEndpintPage";
import Web3AuthEndpointUsersTab from "./components/web3-auth/users/Web3AuthEndpointUsersTab";
import Web3AuthEndpointSettingTab from "./components/web3-auth/setting/Web3AuthEndpointSettingTab";
import Web3AuthEndpointApiKeysTab from "./components/web3-auth/api_keys/Web3AuthEndpointApiKeysTab";

import { WorkingPage } from "./components/layouts/NotData";
import WebAppsPage from "./pages/web-apps/WebAppsPage";
import WebAppCreatePage from "./pages/web-apps/WebAppCreatePage";
import WebAppSinglePage from "./pages/web-apps/WebAppSinglePage";
import WebAppOverviewTab from "./components/web-apps/single-page/WebAppOverviewTab";
import WebAppLogsTab from "./components/web-apps/single-page/WebAppLogsTab";
import WebAppEnvironmentTab from "./components/web-apps/single-page/environment/WebAppEnvironmentTab";
import WebAppPreviewTab from "./components/web-apps/single-page/WebAppPreviewTab";
import WebAppSharingTab from "./components/web-apps/single-page/WebAppSharingTab";
import WebAppSettingsTab from "./components/web-apps/single-page/settings/WebAppSettingsTab";
import WebAppMetricsTab from "./components/web-apps/single-page/WebAppMetricsTab";
import WebAppShellTab from "./components/web-apps/single-page/WebAppShellTab";
import WebAppDisksTab from "./components/web-apps/single-page/WebAppDisksTab";
import WebAppJobsTab from "./components/web-apps/single-page/WebAppJobsTab";
import WebAppScalingTab from "./components/web-apps/single-page/WebAppScalingTab";
import WebAppPlanTab from "./components/web-apps/single-page/WebAppPlanTab";
import MarketApiPage, {
  MarketApiPageMenus,
} from "./pages/market-api/MarketApiPage";
import WalletApiPage, {
  WalletApiPageMenus,
} from "./pages/wallet-api/WalletApiPage";
import BlockApiPage, {
  BlockApiPageMenus,
} from "./pages/block-api/BlockApiPage";
import NftApiPage, { NftApiPageMenus } from "./pages/nft-api/NftApiPage";
import WorkflowStudioPage, {
  WorkflowStudioPageMenus,
} from "./pages/workflow-studio/WorkflowStudioPage";
import Canvas from "./components/workflows/workflows/canvas";
import ServicePage from "./pages/databases/ServicePage";
import GeneralInformationTab from "./components/databases/general-information/GeneralInformationTab";
import UserTab from "./components/databases/users/UserTab";
import AuthorisedIpsTab from "./components/databases/authorised-ips/AuthorisedIpsTab";
import UserCreateTab from "./components/databases/users/UserCreateTab";
import DatabaseLogsTab from "./components/databases/logs/DatabaseLogsTab";
import DatabaseMetricsTab from "./components/databases/metrics/DatabaseMetricsTab";
import DatabasesTab from "./components/databases/databases/DatabasesTab";
import QuerrStatisticsTab from "./components/databases/query-statistics/QuerryStatisticsTab";
import DBSUpgradeNodeTab from "./components/databases/upgrade-node/DBSUpgradeNodeTab";
import DBSCurrentQueriesTab from "./components/databases/queries-in-progress/DBSCurrentQueriesTab";
import DBSServiceIntegrationTab from "./components/databases/service-integration/DBSServiceIntegrationTab";
import DBSBackupsTab from "./components/databases/backups/DBSBackupsTab";
import DBSAdvancedConfigurationsTab from "./components/databases/advanced-configurations/DBSAdvancedConfigurationsTab";
import ServiceCreatePage from "./pages/databases/ServiceCreatePage";
import DBSUpgradePlanTab from "./components/databases/upgrade-plan/DBSUpgradePlanTab";
import DBSUpgradeStorageTab from "./components/databases/upgrade-storage/DBSUpgradeStorageTab";
import DBSPoolsTab from "./components/databases/pools/DBSPoolsTab";
import DBSNamespacesTab from "./components/databases/namespaces/DBSNamespacesTab";
import DBSNamespaceCreateTab from "./components/databases/namespaces/DBSNamespaceCreateTab";

//instances
import InstancesPage, {
  InstancesPageMenus,
} from "./pages/instances/InstancesPage";
import InstanceCreatePage from "./pages/instances/InstanceCreatePage";
import InstancePage from "./pages/instances/InstancePage";
import InstanceVcnConsoleTab from "./components/instances/instance/vnc/InstanceVcnConsoleTab";
import DatabaseServicesPage from "./pages/databases/DatabaseServicesPage";
import InstanceNetworksTab from "./components/instances/instance/networks/InstanceNetworksTab";
import InstanceImagesTab from "./components/instances/instance/images/InstanceImagesTab";
import InstanceRescaleTab from "./components/instances/instance/rescale/InstanceRescaleTab";
import InstanceBackupsTab from "./components/instances/instance/backups/InstanceBackupsTab";
import InstanceDeleteTab from "./components/instances/instance/delete/InstanceDeleteTab";

import InstanceOverviewTab from "./components/instances/instance/overview/InstanceOverviewTab";
import InstanceVolumeCreatePage from "./pages/instances/volumes/InstanceVolumeCreatePage";
import InstanceVolumeEditPage from "./pages/instances/volumes/InstanceVolumeEditPage";

import VolumePage from "./pages/instances/volumes/VolumePage";
import VolumeDeleteTab from "./components/instances/volumes/volume/delete/VolumeDeleteTab";
import VolumeOverviewTab from "./components/instances/volumes/volume/overview/VolumeOverviewTab";
import InstanceVolumeBackupTab from "./components/instances/volumes/volume/backups/InstanceVolumeBackupTab";
import InstanceVolumeSnapshotTab from "./components/instances/volumes/volume/snapshots/InstanceVolumeSnapshotTab";

import App from "./App";
import WebsiteLayout from "./components/layouts/WebsiteLayout";
import IndexPage from "./pages/website/IndexPage";
import ContactUsPage from "./pages/website/ContactUsPage";
import AboutUsPage from "./pages/website/AboutUsPage";
import WebNotFound from "./pages/website/WebNotFound";
import DBSUpgradeNumberOfNodesTab from "./components/databases/upgrade-number-of-nodes/DBSUpgradeNumberOfNodesTab";
import WorkflowTemplatePage from "./pages/workflow-studio/WorkflowTemplatePage";
import WebAppEventsTab from "./components/web-apps/single-page/WebAppEventsTab";
import DomainsPage, { DomainsPageMenus } from "./pages/domains/DomainsPage";
import DomainPage from "./pages/domains/DomainPage";
import DomainProfileTab from "./components/domains/domains/domain/DomainProfileTab";
import DomainCryptoTab from "./components/domains/domains/domain/DomainCryptoTab";
import DomainWebsiteTab from "./components/domains/domains/domain/DomainWebsiteTab";
import DomainDnsRecordsTab from "./components/domains/domains/domain/DomainDnsRecordsTab";
import DomainTransferTab from "./components/domains/domains/domain/DomainTransferTab";
import DomainNameserversTab from "./components/domains/domains/domain/DomainNameserversTab";
import DomainTokenizationTab from "./components/domains/domains/domain/DomainTokenizationTab";
import DomainSellTab from "./components/domains/domains/domain/DomainSellTab";
import DomainBuyAndSellCryptoTab from "./components/domains/domains/domain/DomainBuyAndSellCryptoTab";
import DomainReverseResolutionTab from "./components/domains/domains/domain/DomainReverseResolutionTab";
import DomainEmailTab from "./components/domains/domains/domain/DomainEmailTab";
import DomainAutoRenewalTab from "./components/domains/domains/domain/DomainAutoRenewalTab";
import DappStudioPage from "./pages/dapp-studio/DappStudioPage";
import DappStudioTab from "./components/dapp-studio/studio/DappStudioTab";
import DappPlansTab from "./components/dapp-studio/plans/DappPlansTab";
import BlockchainsPage from "./pages/blockchain-studio/BlockchainsPage";
import BlockchainPage from "./pages/blockchain-studio/BlockchainPage";
import BlockchainOverviewTab from "./components/blockchain-studio/BlockchainOverviewTab";
import BlockchainMetricsTab from "./components/blockchain-studio/BlockchainMetricsTab";
import BlockchainSettingsTab from "./components/blockchain-studio/BlockchainSettingsTab";
import BlockchainCreatePage from "./pages/blockchain-studio/BlockchainCreatePage";
import DataStreamServicesPage from "./pages/data-streaming/DataStreamServicesPage";
import ACLTab from "./components/data-streaming/acl/ACLTab";
import ReplicationFlowsTab from "./components/data-streaming/replication-flows/ReplicationFlowsTab";
import TopicsTab from "./components/data-streaming/topics/TopicsTab";
import WebAppSourceCodeTab from "./components/web-apps/single-page/WebAppSourceCodeTab";
import WebAppAIChatTab from "./components/web-apps/single-page/WebAppAIChatTab";
import ConnectorsTab from "./components/data-streaming/connectors/ConnectorsTab";
import ConnectorPage from "./pages/data-streaming/connectors/ConnectorPage";
import ConnectorTab from "./components/data-streaming/connectors/ConnectorTab";
import DataAnalsisServicesPage from "./pages/data-analysis/DataAnalsisServicesPage";
import UserAclTab from "./components/data-analysis/user-acl/UserAclTab";
import IndexesTab from "./components/data-analysis/indexes/IndexesTab";
import WebAppBuildHistoriesTab from "./components/web-apps/single-page/WebAppBuildHistoriesTab";
import WebAppCreateFromTemplatePage from "./pages/web-apps/WebAppCreateFromTemplatePage";
import K8ServicesPage from "./pages/k8-services/K8ServicesPage";
import K8ServicePage from "./pages/k8-services/K8ServicePage";
import K8CreatePage from "./pages/k8-services/K8CreatePage";
import K8OverviewTab from "./components/kubernetes/service/K8OverviewTab";
import K8IpRestrictionsTab from "./components/kubernetes/ip-restrictions/K8IpRestrictionsTab";

function PrivateRoute({ children }: PropsWithChildren) {
  let loggedIn = useAppSelector(selectIsAuthenticate);
  const location = useLocation();
  const returnUrl = location.pathname + location.search;
  return loggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to={SignInUrl + "?return-url=" + returnUrl} />
  );
}

const routes: Array<RouteObject> = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/",
            element: <WebsiteLayout />,
            children: [
              {
                index: true,
                element: <IndexPage />,
              },
              {
                path: "contact-us",
                element: <ContactUsPage />,
              },
              {
                path: "about-us",
                element: <AboutUsPage />,
              },
              {
                path: "*",
                element: <WebNotFound />,
              },
            ],
          },
          {
            path: "/",
            element: (
              <PrivateRoute>
                <PanelLayout />
              </PrivateRoute>
            ),
            children: [
              {
                index: true,
                // element: <WorkingPage title="Home" />,
                element: <Navigate to={HomeUrl} />,
              },
              {
                path: "rpc-endpoints",
                element: <RpcEndpointsPage />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={RpcEndpointsUrl} />,
                  },
                  ...RpcEndpontsPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "rpc-endpoints",
                children: [
                  {
                    path: ":id",
                    element: <RpcEndpointPage />,
                    children: [
                      {
                        index: true,
                        element: <RpcEndpointGetStartedTab />,
                      },
                      {
                        path: "metrics",
                        element: <RpcEndpointMetricsTab />,
                      },
                      {
                        path: "security",
                        element: <RpcEndpointSecurityTab />,
                      },
                    ],
                  },
                  {
                    path: "create",
                    element: <RpcEndpointCreatePage />,
                  },
                ],
              },
              {
                path: "web-apps",
                children: [
                  {
                    index: true,
                    element: <WebAppsPage />,
                  },
                  {
                    path: "create",
                    element: <WebAppCreatePage />,
                  },
                  {
                    path: "template",
                    element: <WebAppCreateFromTemplatePage />,
                  },
                  {
                    path: ":webAppId",
                    element: <WebAppSinglePage />,
                    children: [
                      {
                        index: true,
                        element: <WebAppOverviewTab />,
                      },
                      {
                        path: "logs",
                        element: <WebAppLogsTab />,
                      },
                      {
                        path: "disks",
                        element: <WebAppDisksTab />,
                      },
                      {
                        path: "env",
                        element: <WebAppEnvironmentTab />,
                      },
                      {
                        path: "shell",
                        element: <WebAppShellTab />,
                      },
                      {
                        path: "previews",
                        element: <WebAppPreviewTab />,
                      },
                      {
                        path: "jobs",
                        element: <WebAppJobsTab />,
                      },
                      {
                        path: "sharing",
                        element: <WebAppSharingTab />,
                      },
                      {
                        path: "metrics",
                        element: <WebAppMetricsTab />,
                      },
                      {
                        path: "scaling",
                        element: <WebAppScalingTab />,
                      },
                      {
                        path: "settings",
                        element: <WebAppSettingsTab />,
                      },
                      {
                        path: "plan",
                        element: <WebAppPlanTab />,
                      },
                      {
                        path: "deploys/:deployId",
                        element: <WorkingPage title="deploys" />,
                      },
                      {
                        path: "events",
                        element: <WebAppEventsTab />,
                      },
                      {
                        path: "source-code",
                        element: <WebAppSourceCodeTab />,
                      },
                      {
                        path: "ai-chat",
                        element: <WebAppAIChatTab />,
                      },
                      {
                        path: "build-history",
                        element: <WebAppBuildHistoriesTab />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "workflow-studio",
                element: <WorkflowStudioPage />,
                children: [
                  ...WorkflowStudioPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                  {
                    index: true,
                    element: <Navigate to={WorkflowsUrl} />,
                  },
                ],
              },
              {
                path: "workflow-studio",
                children: [
                  {
                    path: "canvas",
                    element: <Canvas />,
                  },
                  {
                    path: "canvas/:id",
                    element: <Canvas />,
                  },
                  {
                    path: "template",
                    element: <WorkflowTemplatePage />,
                  },
                ],
              },
              {
                path: "web3-auth",
                element: <Web3AuthEndpintsPage />,
                children: [
                  ...Web3AuthEndpontsPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                  {
                    index: true,
                    element: <Navigate to={Web3AuthEndpointsUrl} />,
                  },
                ],
              },
              {
                path: "web3-auth",
                children: [
                  {
                    path: ":endpoindId",
                    element: <Web3AuthEndpintPage />,
                    children: [
                      {
                        index: true,
                        element: <Web3AuthEndpointUsersTab />,
                      },
                      {
                        path: "setting",
                        element: <Web3AuthEndpointSettingTab />,
                      },
                      {
                        path: "api-keys",
                        element: <Web3AuthEndpointApiKeysTab />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "nft-api",
                element: <NftApiPage />,
                children: [
                  ...NftApiPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                  {
                    index: true,
                    element: <Navigate to={NftApiOverviewUrl} />,
                  },
                ],
              },
              {
                path: "wallet-api",
                element: <WalletApiPage />,
                children: [
                  ...WalletApiPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                  {
                    index: true,
                    element: <Navigate to={WalletApiOverviewUrl} />,
                  },
                ],
              },
              {
                path: "market-api",
                element: <MarketApiPage />,
                children: [
                  ...MarketApiPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                  {
                    index: true,
                    element: <Navigate to={MarketApiOverviewUrl} />,
                  },
                ],
              },
              {
                path: "block-api",
                element: <BlockApiPage />,
                children: [
                  ...BlockApiPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                  {
                    index: true,
                    element: <Navigate to={BlockApiOverviewUrl} />,
                  },
                ],
              },
              {
                path: "block-events",
                element: <BlockEventsPage />,
                children: [
                  ...BlockEventsPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "block-events/destinations/:destId",
                element: <BlockEventDestinationPage />,
              },
              {
                path: "block-events/create",
                element: <BlockEventCreatePage />,
              },
              {
                path: "block-events/:blockEventId/details",
                element: <BlockEventPage />,
              },
              {
                path: "ipfs",
                element: <IpfsPage />,
                children: [
                  ...IpfsPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "s3",
                element: <S3Page />,
                children: [
                  ...S3PageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "s3/buckets/:bucketName",
                children: [
                  {
                    index: true,
                    element: <S3BucketPage />,
                  },
                  {
                    path: "settings",
                    element: <S3BucketSettingPage />,
                  },
                ],
              },
              {
                path: "s3/bucket/create",
                element: <S3BucketCreatePage />,
              },
              {
                path: "s3/event/add",
                element: <S3EventAddPage />,
                children: [
                  {
                    index: true,
                    element: <S3SelectDestination />,
                  },
                  {
                    path: ":destination",
                    element: <S3EventEditorPage />,
                  },
                ],
              },
              {
                path: "s3/tier/add",
                element: <S3TierAddPage />,
                children: [
                  {
                    index: true,
                    element: <S3SelectTierType />,
                  },
                  {
                    path: ":type",
                    element: <S3TierEditorPage />,
                  },
                ],
              },
              {
                path: "database",
                children: [
                  {
                    path: "services",
                    element: <DatabaseServicesPage />,
                  },
                  {
                    index: true,
                    element: <Navigate to={DataServicesUrl("operational")} />,
                  },
                ],
              },
              {
                path: "database",
                children: [
                  {
                    path: "create",
                    element: <ServiceCreatePage category="operational" />,
                  },
                  {
                    path: ":engine/:databaseId",
                    element: <ServicePage category="operational" />,
                    children: [
                      {
                        index: true,
                        element: (
                          <GeneralInformationTab category="operational" />
                        ),
                      },
                      {
                        path: "users",
                        children: [
                          {
                            index: true,
                            element: <UserTab />,
                          },
                          {
                            path: "create",
                            element: <UserCreateTab />,
                          },
                          {
                            path: ":id",
                            element: <UserCreateTab />,
                          },
                        ],
                      },
                      {
                        path: "databases",
                        element: <DatabasesTab />,
                      },
                      {
                        path: "allowed-ips",
                        element: <AuthorisedIpsTab category="operational" />,
                      },
                      {
                        path: "logs",
                        element: <DatabaseLogsTab category="operational" />,
                      },
                      {
                        path: "metrics",
                        element: <DatabaseMetricsTab category="operational" />,
                      },
                      {
                        path: "queryStatistics",
                        element: <QuerrStatisticsTab />,
                      },
                      {
                        path: "current-queries",
                        element: <DBSCurrentQueriesTab />,
                      },
                      {
                        path: "service-integrations",
                        element: (
                          <DBSServiceIntegrationTab category="operational" />
                        ),
                      },
                      {
                        path: "advanced-configurations",
                        element: (
                          <DBSAdvancedConfigurationsTab category="operational" />
                        ),
                      },
                      {
                        path: "upgrade-node",
                        element: <DBSUpgradeNodeTab category="operational" />,
                      },
                      {
                        path: "upgrade-number-of-nodes",
                        element: (
                          <DBSUpgradeNumberOfNodesTab category="operational" />
                        ),
                      },
                      {
                        path: "upgrade-plan",
                        element: <DBSUpgradePlanTab category="operational" />,
                      },
                      {
                        path: "upgrade-storage",
                        element: (
                          <DBSUpgradeStorageTab category="operational" />
                        ),
                      },
                      {
                        path: "backup",
                        children: [
                          {
                            index: true,
                            element: <DBSBackupsTab category="operational" />,
                          },
                          {
                            path: "fork",
                            element: (
                              <ServiceCreatePage category="operational" />
                            ),
                          },
                        ],
                      },
                      {
                        path: "namespace",
                        children: [
                          {
                            index: true,
                            element: <DBSNamespacesTab />,
                          },
                          {
                            path: "create",
                            element: <DBSNamespaceCreateTab />,
                          },
                        ],
                      },
                      {
                        path: "pools",
                        element: <DBSPoolsTab />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "data-streaming",
                children: [
                  {
                    index: true,
                    element: <Navigate to={DataServicesUrl("streaming")} />,
                  },
                  {
                    path: "services",
                    element: <DataStreamServicesPage />,
                  },
                ],
              },
              {
                path: "data-streaming",
                children: [
                  {
                    path: "create",
                    element: <ServiceCreatePage category="streaming" />,
                  },
                  {
                    path: ":engine/:databaseId",
                    element: <ServicePage category="streaming" />,
                    children: [
                      {
                        index: true,
                        element: <GeneralInformationTab category="streaming" />,
                      },
                      {
                        path: "users",
                        children: [
                          {
                            index: true,
                            element: <UserTab />,
                          },
                          {
                            path: "create",
                            element: <UserCreateTab />,
                          },
                          {
                            path: ":id",
                            element: <UserCreateTab />,
                          },
                        ],
                      },
                      {
                        path: "acl",
                        element: <ACLTab />,
                      },
                      {
                        path: "allowed-ips",
                        element: <AuthorisedIpsTab category="streaming" />,
                      },
                      {
                        path: "logs",
                        element: <DatabaseLogsTab category="streaming" />,
                      },
                      {
                        path: "metrics",
                        element: <DatabaseMetricsTab category="streaming" />,
                      },
                      {
                        path: "topics",
                        element: <TopicsTab />,
                      },
                      {
                        path: "service-integrations",
                        element: (
                          <DBSServiceIntegrationTab category="streaming" />
                        ),
                      },
                      {
                        path: "replications",
                        element: <ReplicationFlowsTab />,
                      },
                      {
                        path: "connectors",

                        children: [
                          {
                            index: true,
                            element: <ConnectorsTab />,
                          },
                          {
                            path: ":connectorId",
                            element: <ConnectorPage />,
                            children: [
                              {
                                path: "add",
                                element: <ConnectorTab />,
                              },
                              {
                                path: "edit",
                                element: <ConnectorTab />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "advanced-configurations",
                        element: (
                          <DBSAdvancedConfigurationsTab category="streaming" />
                        ),
                      },
                      {
                        path: "upgrade-node",
                        element: <DBSUpgradeNodeTab category="streaming" />,
                      },
                      {
                        path: "upgrade-number-of-nodes",
                        element: (
                          <DBSUpgradeNumberOfNodesTab category="streaming" />
                        ),
                      },
                      {
                        path: "upgrade-plan",
                        element: <DBSUpgradePlanTab category="streaming" />,
                      },
                      {
                        path: "upgrade-storage",
                        element: <DBSUpgradeStorageTab category="streaming" />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "data-analysis",
                children: [
                  {
                    index: true,
                    element: <Navigate to={DataServicesUrl("analysis")} />,
                  },
                  {
                    path: "services",
                    element: <DataAnalsisServicesPage />,
                  },
                ],
              },
              {
                path: "data-analysis",
                children: [
                  {
                    path: "create",
                    element: <ServiceCreatePage category="analysis" />,
                  },
                  {
                    path: ":engine/:databaseId",
                    element: <ServicePage category="analysis" />,
                    children: [
                      {
                        index: true,
                        element: <GeneralInformationTab category="analysis" />,
                      },
                      {
                        path: "users",
                        children: [
                          {
                            index: true,
                            element: <UserTab />,
                          },
                          {
                            path: "create",
                            element: <UserCreateTab />,
                          },
                          {
                            path: ":id",
                            element: <UserCreateTab />,
                          },
                        ],
                      },
                      {
                        path: "acl",
                        element: <UserAclTab />,
                      },
                      {
                        path: "indexes",
                        element: <IndexesTab />,
                      },
                      {
                        path: "backup",
                        children: [
                          {
                            index: true,
                            element: <DBSBackupsTab category="analysis" />,
                          },
                          {
                            path: "fork",
                            element: <ServiceCreatePage category="analysis" />,
                          },
                        ],
                      },
                      {
                        path: "allowed-ips",
                        element: <AuthorisedIpsTab category="analysis" />,
                      },
                      {
                        path: "logs",
                        element: <DatabaseLogsTab category="analysis" />,
                      },
                      {
                        path: "metrics",
                        element: <DatabaseMetricsTab category="analysis" />,
                      },
                      {
                        path: "service-integrations",
                        element: (
                          <DBSServiceIntegrationTab category="analysis" />
                        ),
                      },
                      {
                        path: "advanced-configurations",
                        element: (
                          <DBSAdvancedConfigurationsTab category="analysis" />
                        ),
                      },
                      {
                        path: "upgrade-node",
                        element: <DBSUpgradeNodeTab category="analysis" />,
                      },
                      {
                        path: "upgrade-number-of-nodes",
                        element: (
                          <DBSUpgradeNumberOfNodesTab category="analysis" />
                        ),
                      },
                      {
                        path: "upgrade-plan",
                        element: <DBSUpgradePlanTab category="analysis" />,
                      },
                      {
                        path: "upgrade-storage",
                        element: <DBSUpgradeStorageTab category="analysis" />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "instances",
                element: <InstancesPage />,
                children: [
                  ...InstancesPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "instances",
                children: [
                  {
                    path: "create",
                    element: <InstanceCreatePage />,
                  },
                  {
                    path: ":instanceId",
                    element: <InstancePage />,
                    children: [
                      {
                        index: true,
                        element: <InstanceOverviewTab />,
                      },
                      { path: "vnc", element: <InstanceVcnConsoleTab /> },
                      { path: "networks", element: <InstanceNetworksTab /> },
                      { path: "images", element: <InstanceImagesTab /> },
                      { path: "rescale", element: <InstanceRescaleTab /> },
                      { path: "backups", element: <InstanceBackupsTab /> },
                      { path: "delete", element: <InstanceDeleteTab /> },
                    ],
                  },
                  {
                    path: "volumes/:volumeId",
                    element: <VolumePage />,
                    children: [
                      {
                        index: true,
                        element: <VolumeOverviewTab />,
                      },
                      {
                        path: "volume-backup/list",
                        element: <InstanceVolumeBackupTab />,
                      },
                      {
                        path: "volume-snapshots",
                        element: <InstanceVolumeSnapshotTab />,
                      },
                      { path: "delete", element: <VolumeDeleteTab /> },
                    ],
                  },
                  {
                    path: "volumes/create",
                    element: <InstanceVolumeCreatePage />,
                  },
                  {
                    path: "volumes/:id/edit",
                    element: <InstanceVolumeEditPage />,
                  },
                  {
                    path: "volumes/:id/volume-backup/list/:backupId/create",
                    element: <InstanceVolumeEditPage />,
                  },

                  {
                    path: "volumes/:id/volume-snapshots/:backupId/create",
                    element: <InstanceVolumeEditPage />,
                  },
                ],
              },
              {
                path: "settings",
                element: <SettingsPage />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={SettingsProfileUrl} />,
                  },
                  ...SettingsPageMenus({}).map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "domains",
                element: <DomainsPage />,
                children: [
                  ...DomainsPageMenus.map((m) => ({
                    path: m.url,
                    element: m.element,
                  })),
                ],
              },
              {
                path: "domains",
                children: [
                  {
                    path: ":id",
                    element: <DomainPage />,
                    children: [
                      {
                        index: true,
                        element: <DomainProfileTab />,
                      },
                      {
                        path: "crypto",
                        element: <DomainCryptoTab />,
                      },
                      {
                        path: "website",
                        element: <DomainWebsiteTab />,
                      },
                      {
                        path: "dns-records",
                        element: <DomainDnsRecordsTab />,
                      },
                      {
                        path: "transfer",
                        element: <DomainTransferTab />,
                      },
                      {
                        path: "nameservers",
                        element: <DomainNameserversTab />,
                      },
                      {
                        path: "tokenization",
                        element: <DomainTokenizationTab />,
                      },
                      {
                        path: "sell",
                        element: <DomainSellTab />,
                      },
                      {
                        path: "buy-and-sell-crypto",
                        element: <DomainBuyAndSellCryptoTab />,
                      },

                      {
                        path: "reverse-resolution",
                        element: <DomainReverseResolutionTab />,
                      },
                      {
                        path: "email",
                        element: <DomainEmailTab />,
                      },
                      {
                        path: "auto-renewal",
                        element: <DomainAutoRenewalTab />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "dapp-studio",
                element: <DappStudioPage />,
                children: [
                  {
                    index: true,
                    element: <DappStudioTab />,
                  },
                  {
                    path: "plans",
                    element: <DappPlansTab />,
                  },
                ],
              },
              {
                path: "blockchains",
                children: [
                  {
                    index: true,
                    element: <BlockchainsPage />,
                  },
                  {
                    path: ":blockchainId",
                    element: <BlockchainPage />,
                    children: [
                      {
                        index: true,
                        element: <BlockchainOverviewTab />,
                      },
                      {
                        path: "metrics",
                        element: <BlockchainMetricsTab />,
                      },
                      {
                        path: "settings",
                        element: <BlockchainSettingsTab />,
                      },
                    ],
                  },
                  {
                    path: "create",
                    element: <BlockchainCreatePage />,
                  },
                ],
              },
              {
                path: "kubernetes-services",
                children: [
                  {
                    index: true,
                    element: <K8ServicesPage />,
                  },
                  {
                    path: "create",
                    element: <K8CreatePage />,
                  },
                  {
                    path: ":kubernetesId",
                    element: <K8ServicePage />,
                    children: [
                      {
                        index: true,
                        element: <K8OverviewTab />,

                        // element: <WorkingPage />,
                      },
                      {
                        path: "node-pools",
                        // element: <K8NodePoolsTab />,
                        element: <WorkingPage />,
                      },
                      {
                        path: "api-server-access",
                        element: <K8IpRestrictionsTab />,
                      },
                      {
                        path: "logs",
                        // element: <K8LogsTab />,
                        element: <WorkingPage />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "*",
                element: <NotFoundPage />,
              },
            ],
          },
        ],
      },
      {
        path: "/auth",
        element: <AuthLayout />,
        children: [
          {
            path: "register",
            element: <SignUp />,
          },
          {
            path: "login",
            element: <SignIn />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "reset-password/:token",
            element: <ResetPassword />,
          },
          {
            path: "email-verification/:token",
            element: <EmailVerification />,
          },
        ],
      },
      {
        path: "/private",
        element: (
          <PrivateRoute>
            <AuthLayout />
          </PrivateRoute>
        ),
        children: [
          {
            path: "teams/invited/:env/:token",
            element: <AcceptInvitedTeamPage />,
          },
        ],
      },
    ],
  },
];
export const router = createBrowserRouter(routes);
