import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { K8NodePool, K8Service, K8ServiceState } from "../../types/kubernetes";
import {
  createK8IpRestrictionsApi,
  getK8IpRestrictionsApi,
  getK8LogsApi,
  getK8NodePoolsApi,
  getK8ServiceApi,
  K8ResetClusterApi,
} from "../../apis/kubernetesApi";
import { dummyK8Service } from "./k8ServicesSlice";

const initialState: K8ServiceState = {
  service: null,
  loading: false,
  actionLoading: false, //for create, delete, rename
  showChangeNameModal: false,

  //pools
  pools: [],
  poolsLoading: false,

  //ip restrictions
  ipRestrictions: [],
  ipRestrictionsLoading: false,
  ipRestrictionsActionLoading: false,
  selectedIpRestriction: null,
  showIpRestrictionEditor: false,

  //logs
  logs: [],
  logsLoading: false,
};

export const getK8ServiceAsync = createAsyncThunk<
  { service: K8Service },
  { id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-service",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8ServiceAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );
      const response = await getK8ServiceApi(id);
      const service = response.data.Result;
      return fulfillWithValue({ service });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getK8LogsAsync = createAsyncThunk<
  { logs: Array<any> },
  { id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-service/logs",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8LogsAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );

      const response = await getK8LogsApi(id);
      const logs = response.data.Result;

      return fulfillWithValue({ logs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getK8NodePoolsAsync = createAsyncThunk<
  { pools: Array<K8NodePool> },
  {
    id: string;
  },
  IThunkRejectValue
>("k8-service/pools", async ({ id }, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getK8NodePoolsApi(id);
    const pools = response.data.Result;
    return fulfillWithValue({ pools });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const K8ResetClusterAsync = createAsyncThunk<
  boolean,
  { id: string },
  IThunkRejectValue
>("k8-service/reset-cluster", async ({ id }, { rejectWithValue }) => {
  try {
    await K8ResetClusterApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getK8IpRestrictionsAsync = createAsyncThunk<
  Array<string>,
  {
    id: string;
  },
  IThunkRejectValue
>(
  "k8-service/ip-restrictions",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getK8IpRestrictionsApi(id);
      return fulfillWithValue(response.data.Result || []);
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createK8IpRestrictionsAsync = createAsyncThunk<
  boolean,
  {
    id: string;
    ips: Array<string>;
  },
  IThunkRejectValue
>(
  "k8-service/ip-restrictions/create",
  async ({ id, ips }, { rejectWithValue }) => {
    try {
      await createK8IpRestrictionsApi(id, ips);
      return false;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const k8ServiceSlice = createSlice({
  name: "k8ServiceSlice",
  initialState,
  reducers: {
    handleClearK8ServiceSlice: (state) => {
      state.service = null;
      state.showChangeNameModal = initialState.showChangeNameModal;
      state.pools = initialState.pools;
      state.logs = initialState.logs;
    },
    handleToggleK8IpRestrictionEditor: (
      state,
      action: PayloadAction<{ status: boolean; ipRestriction?: string | null }>
    ) => {
      state.showIpRestrictionEditor = action.payload.status;
      state.selectedIpRestriction = action.payload.ipRestriction || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getK8ServiceAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getK8ServiceAsync.fulfilled, (state, action) => {
        const { service } = action.payload;
        state.service = service;
        state.loading = false;
      })
      .addCase(getK8ServiceAsync.rejected, (state, { payload }) => {
        state.loading = false;
        //DEV
        state.service = dummyK8Service;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //pools
    builder
      .addCase(getK8NodePoolsAsync.pending, (state) => {
        state.poolsLoading = true;
      })
      .addCase(
        getK8NodePoolsAsync.fulfilled,
        (state, { payload: { pools } }) => {
          state.pools = pools;
          state.poolsLoading = false;
        }
      )
      .addCase(getK8NodePoolsAsync.rejected, (state, { payload }) => {
        state.poolsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //ip restrictions
    builder
      .addCase(getK8IpRestrictionsAsync.pending, (state, action) => {
        state.ipRestrictionsLoading = true;
      })
      .addCase(getK8IpRestrictionsAsync.fulfilled, (state, { payload }) => {
        state.ipRestrictions = payload;
        state.ipRestrictionsLoading = false;
      })
      .addCase(getK8IpRestrictionsAsync.rejected, (state, { payload }) => {
        state.ipRestrictionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createK8IpRestrictionsAsync.pending, (state) => {
        state.ipRestrictionsActionLoading = true;
      })
      .addCase(createK8IpRestrictionsAsync.fulfilled, (state) => {
        state.ipRestrictionsActionLoading = false;
      })
      .addCase(createK8IpRestrictionsAsync.rejected, (state, { payload }) => {
        state.ipRestrictionsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //logs
    builder
      .addCase(getK8LogsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.logsLoading = true;
      })
      .addCase(getK8LogsAsync.fulfilled, (state, { payload: { logs } }) => {
        state.logs = logs;
        state.logsLoading = false;
      })
      .addCase(getK8LogsAsync.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectK8Service = (state: RootState) => state.k8Service.service;
export const selectK8ServiceLoading = (state: RootState) =>
  state.k8Service.loading;

//pools
export const selectK8Pools = (state: RootState) => state.k8Service.pools;
export const selectK8PoolsLoading = (state: RootState) =>
  state.k8Service.poolsLoading;

//logs
export const selectK8IpRestrictions = (state: RootState) =>
  state.k8Service.ipRestrictions;
export const selectK8IpRestrictionsLoading = (state: RootState) =>
  state.k8Service.ipRestrictionsLoading;
export const selectK8IpRestrictionsActionLoading = (state: RootState) =>
  state.k8Service.ipRestrictionsActionLoading;
export const selectK8IpRestrictionEditor = (state: RootState) =>
  state.k8Service.showIpRestrictionEditor;
export const selectK8SelectedIpRestriction = (state: RootState) =>
  state.k8Service.selectedIpRestriction;

//logs
export const selectK8Logs = (state: RootState) => state.k8Service.logs;
export const selectK8LogsLoading = (state: RootState) =>
  state.k8Service.logsLoading;

export const { handleClearK8ServiceSlice, handleToggleK8IpRestrictionEditor } =
  k8ServiceSlice.actions;
export default k8ServiceSlice.reducer;
