import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Helmet } from "react-helmet";
import {
  EmptyState,
  Typography,
  Flex,
  Switcher,
  Loading,
  cn,
  Button,
} from "djuno-design";
import {
  getNotificationsAsync,
  handleReadNotif,
  handleToggleNotifAudio,
  markAsReadNotificationAsync,
  selectNotifications,
  selectNotificationsAudioAllowed,
  selectNotificationsLoading,
} from "../../../store/notifications/notificationsSlice";
import { formatTimestamp, timeAgo } from "../../../utils/date";
import { Link } from "react-router-dom";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/keep_both.svg";
import { Notification } from "../../../types/notifications";

const SettingsNotificationsTab = () => {
  const notifications = useAppSelector(selectNotifications);
  const notificationsLoading = useAppSelector(selectNotificationsLoading);
  const audioAllowed = useAppSelector(selectNotificationsAudioAllowed);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!notificationsLoading)
      dispatch(
        getNotificationsAsync({ withoutLoading: notifications.length > 0 })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Notifications</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center">
        {/* <div className="flex items-center gap-2">
          <Typography.Text>Notifications</Typography.Text>
        </div> */}
        <div className="flex items-center gap-1 md:gap-2">
          <Typography.Text size="sm">Notification sound</Typography.Text>
          <Switcher
            uiSize="small"
            value={audioAllowed}
            onChange={() => dispatch(handleToggleNotifAudio())}
          />
        </div>
      </div>
      <div className="mt-10 w-full">
        <Flex direction="col" className="w-full gap-3">
          {notificationsLoading && (
            <Flex items="center" justify="center" className="min-h-[300px]">
              <Loading borderSize={2} />
            </Flex>
          )}
          <ol className="relative border-s border-gray-200 dark:border-gray-700">
            {!notificationsLoading &&
              notifications.map((notification, i) => (
                <NotificationItem key={i} notification={notification} />
              ))}
          </ol>
          {!notificationsLoading && notifications.length === 0 && (
            <EmptyState
              text="The notification inbox is empty"
              className="min-h-[300px]"
            />
          )}
        </Flex>
      </div>
    </>
  );
};

export const NotificationItem: React.FC<{
  notification: Notification;
}> = ({ notification }) => {
  const [isRead, setIsRead] = useState(notification.IsRead);
  const [markLoading, setMarkLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleMardAsRead = useCallback(
    (notifId: number) => {
      if (!markLoading) {
        setMarkLoading(true);
        dispatch(markAsReadNotificationAsync({ notificationId: notifId })).then(
          (action) => {
            if (action.type === "notifications/mark-as-read/fulfilled") {
              setIsRead(true);
              dispatch(handleReadNotif(notifId));
            }
            setMarkLoading(false);
          }
        );
      }
    },
    [dispatch, markLoading]
  );

  return (
    <li
      className="mb-10 ms-6 last:mb-0 relative"
      id={`notif-` + notification.Id.toString()}
    >
      <span
        className={cn(
          "absolute flex items-center justify-center w-2 h-2 rounded-full -left-7 top-1 ring-4",
          {
            "bg-primary-300 ring-primary-50 dark:ring-primary-500/10 dark:bg-primary-400":
              notification.IsRead,
            "bg-green-500 ring-green-50 dark:ring-green-500/10 dark:bg-green-500":
              !notification.IsRead || notification.IsRead === undefined,
          }
        )}
      />
      <div className="flex flex-col gap-1">
        <Flex items="center" justify="between" className="w-full">
          <Typography.Title level={6} className="!text-sm !mb-0">
            {notification.NotificationTitle}
          </Typography.Title>
          {notification.CreatedAt && (
            <Typography.Text className="!text-xs" uiType="secondary">
              {
                formatTimestamp(notification.CreatedAt, "MM/DD/YYYY HH:mm A", {
                  isUTC: false,
                }).datetime
              }
              <br />
              {timeAgo(notification.CreatedAt)}
            </Typography.Text>
          )}
        </Flex>

        <Typography.Text className="!text-sm mt-2">
          {notification.Content}
        </Typography.Text>

        <Flex items="center" className="gap-1 mt-3">
          {notification.Link && (
            <Link to={notification.Link} onClick={(e) => e.stopPropagation()}>
              <Button uiSize="small" uiType="light">
                view
              </Button>
            </Link>
          )}
          {!isRead && (
            <Button
              uiSize="small"
              uiType="icon"
              className="group"
              onClick={(e) => {
                e.stopPropagation();
                handleMardAsRead(notification.Id);
              }}
              loading={markLoading}
            >
              <Typography.Text
                size="xs"
                className="!text-primary-400 group-hover:!text-primary-500"
              >
                mark as read
              </Typography.Text>
            </Button>
          )}
          {isRead && (
            <Typography.Text
              size="xs"
              className="!text-primary-500 flex items-center gap-0.5"
            >
              <CheckIcon className="w-4 h-4" />
              read
            </Typography.Text>
          )}
        </Flex>
      </div>
    </li>
  );
};

export default SettingsNotificationsTab;
