import { Helmet } from "react-helmet";
import {
  getDatabaseServiceAsync,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import { DataServiceGeneralInformationUrl } from "../../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import {
  DatabaseServicePlansInput,
  dbsPeriodItems,
  groupAvailabilityByPlan,
} from "../ServiceCreateComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceUpdatePlanSchema } from "../../../utils/validations";
import {
  DBSAddon,
  DBSAvailability,
  DBSCategory,
  DBSGroupAvailability,
} from "../../../types/database";
import {
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDBSCatalogAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDBSCatalog,
  selectDBSCatalogLoading,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import RadioGrouper from "../../general/RadioGrouper";
import { Button, Typography } from "djuno-design";

const DBSUpgradePlanTab: React.FC<{ category: DBSCategory }> = ({
  category,
}) => {
  const service = useAppSelector(selectDatabaseService);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(DatabaseServiceUpdatePlanSchema()),
    reValidateMode: "onChange",
  });

  // const [nodeTypes, setNodeTypes] = useState<Array<DBSNodeType>>([]);

  const [planAvailability, setPlanAvailability] = useState<
    Array<DBSAvailability>
  >([]);

  // selected planGroup
  // const [selectedPlanGroup, setSelectedPlanGroup] = useState<
  //   DBSGroupAvailability | undefined
  // >(undefined);

  const [addons, setAddons] = useState<Array<DBSAddon>>([]);
  const [period, setPeriod] = useState<"month" | "hour">("hour");

  const selectedPlan = watch("SelectedPlan");
  // const selectedNodeType = watch("SelectedNodeType");

  useEffect(() => {
    if (capabilities === undefined) dispatch(getDBSCapabilitiesAsync());
  }, [dispatch, capabilities]);

  useEffect(() => {
    if (availability === undefined) dispatch(getDBSAvailabilityAsync());
  }, [dispatch, availability]);

  useEffect(() => {
    if (catalog === undefined) dispatch(getDBSCatalogAsync());
  }, [dispatch, catalog]);

  // make plan groups from planAvailability
  const planGruops: Array<DBSGroupAvailability> = useMemo(() => {
    if (capabilities && service) {
      const { planGroups } = groupAvailabilityByPlan(
        planAvailability,
        capabilities
      );
      setValue("SelectedPlan", service.plan);
      // setSelectedPlanGroup(planGroups.find((pg) => pg.plan === service.plan));
      return planGroups;
    } else {
      return [];
    }
  }, [capabilities, planAvailability, setValue, service]);

  // set selectedPlanGroup state when selected plan is changing
  // useEffect(() => {
  //   if (selectedPlan && planGruops) {
  //     const planGroup = planGruops.find((pg) => pg.plan === selectedPlan);
  //     setSelectedPlanGroup(planGroup);
  //   }
  // }, [planGruops, selectedPlan]);

  // make nodes by selected planGroup and selected region
  // useEffect(() => {
  //   if (selectedPlanGroup && capabilities && service) {
  //     const availability = filterAvailabilitiesByRegion(
  //       selectedPlanGroup.availability,
  //       service.nodes[0].region
  //     );

  //     const nodeTypes = getNodeTypesFromAvailability(
  //       availability,
  //       capabilities,
  //       service.version
  //     );
  //     if (nodeTypes && nodeTypes.length > 0) {
  //       setNodeTypes(nodeTypes);
  //       const nodeType = nodeTypes.find(
  //         (nt) => nt.flavor.name === service.flavor
  //       );
  //       if (nodeType) {
  //         setValue("SelectedNodeType", service.flavor);
  //       } else {
  //         setValue("SelectedNodeType", nodeTypes[0].flavor.name);
  //       }
  //     }
  //   }
  // }, [capabilities, selectedPlanGroup, setValue, service]);

  //filter addons by selected engine and period
  useEffect(() => {
    if (catalog && service) {
      const planCode = `databases.${service.engine}`;
      const filteredAddons = catalog.addons
        .filter((addon) => addon.planCode.includes(planCode))
        .filter((addon) => addon.planCode.includes(period));
      setAddons(filteredAddons);
    }
  }, [catalog, period, service]);

  // process plansAvailability after changing -> selected engine, selected version
  useEffect(() => {
    if (service) {
      const plansAvailability = availability?.filter(
        (a) =>
          a.lifecycle.status === "STABLE" &&
          a.engine === service.engine &&
          a.network === "public" &&
          a.version === service.version
      );
      setPlanAvailability(plansAvailability || []);
    }
  }, [availability, service]);

  // select node type
  // const handleSelectNodeType = useCallback(
  //   (nodeType: DBSNodeType) => {
  //     const prevNodeType = selectedNodeType;
  //     if (prevNodeType !== nodeType.flavor.name) {
  //       setValue("SelectedNodeType", nodeType.flavor.name);
  //     }
  //   },
  //   [selectedNodeType, setValue]
  // );

  const disabledIndex = useMemo(() => {
    if (service) {
      const planNames = planGruops.map((pg) => pg.plan);
      return planNames.indexOf(service.plan);
    }
    return -1;
  }, [planGruops, service]);

  const handleSubmitForm = () => {
    if (service && !actionLoading) {
      const updateData = {
        description: service.description,
        plan: selectedPlan,
        version: service.version,
        flavor: service.flavor,
      };
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: updateData,
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          dispatch(
            getDatabaseServiceAsync({ engine: service.engine, id: service.id })
          );

          navigate(
            DataServiceGeneralInformationUrl(service.engine, service.id)
          );
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Upgrade plan </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="p-4">
        {service && (
          <div className="flex items-center justify-between">
            <div
              className="group px-1 items-center flex transition-all duration-150 cursor-pointer gap-x-0.5"
              onClick={() =>
                navigate(
                  DataServiceGeneralInformationUrl(service.engine, service.id)
                )
              }
            >
              <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />
              <Typography.Text className="!text-sm !text-primary-500">
                Back to “Information”
              </Typography.Text>
            </div>
            <RadioGrouper
              items={dbsPeriodItems}
              selected={dbsPeriodItems.find((i) => i.value === period)}
              setSelected={(item) => setPeriod(item.value)}
              dir="row"
            />
          </div>
        )}

        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full">
            <DatabaseServicePlansInput
              control={control}
              plans={planGruops}
              addons={addons}
              period={period}
              errorMessage={errors.SelectedPlan?.message}
              disabledIndex={disabledIndex}
              selectedService={service}
            />
            {/* {selectedPlan !== service?.plan && (
              <Table containerClassName="!min-h-min">
                <TableHead>
                  <TableRow>
                    <TableTH lable="Type" />
                    <TableTH lable="vCores" />
                    <TableTH lable="Memory" />
                    <TableTH lable="Usable Storage" />
                    <TableTH lable={`Cost/${period}/node (estimated)`} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nodeTypes.map((nodeType, index) => {
                    const {
                      flavor: {
                        specifications: { memory },
                        name: flavorName,
                      },
                      availability: {
                        specifications: { storage },
                      },
                    } = nodeType;

                    let Memory;
                    let minStorage;
                    let maxStorage;

                    if (memory) {
                      const memoryBinary = binarySize(
                        memory.value,
                        memory.unit.slice()[0],
                        1000
                      );
                      Memory = humanizeSize(memoryBinary, {
                        binaryBaseValue: 1000,
                      });
                    }

                    if (storage) {
                      const { minimum, maximum } = storage;
                      const minStorageBinary = binarySize(
                        minimum.value,
                        minimum.unit.slice()[0],
                        1000
                      );
                      minStorage = humanizeSize(minStorageBinary, {
                        binaryBaseValue: 1000,
                      });

                      const maxStorageBinary = binarySize(
                        maximum.value,
                        maximum.unit.slice()[0],
                        1000
                      );
                      maxStorage = humanizeSize(maxStorageBinary, {
                        binaryBaseValue: 1000,
                      });
                    }

                    const filteredAddons = addons.filter((addon) =>
                      addon.planCode.includes(selectedPlan + "-" + flavorName)
                    );

                    return (
                      <TableRow
                        key={index}
                        selected={nodeType.flavor.name === selectedNodeType}
                        className="cursor-pointer"
                        onClick={() => handleSelectNodeType(nodeType)}
                      >
                        <TableTD className="w-36">
                          <Text className="text-xs md:text-sm whitespace-nowrap">
                            {capitalizeFirstLetter(nodeType.flavor.name)}
                          </Text>
                        </TableTD>
                        <TableTD>
                          <Text className="text-xs md:text-sm">
                            {nodeType.flavor.specifications.core}
                          </Text>
                        </TableTD>
                        <TableTD>
                          <Text className="text-xs md:text-sm">
                            {Memory?.number && Memory?.join("B")}
                          </Text>
                        </TableTD>
                        <TableTD>
                          <Text className="text-xs md:text-sm whitespace-nowrap">
                            {minStorage && maxStorage && (
                              <>
                                {minStorage.number !== maxStorage.number ? (
                                  <>{`From ${minStorage.join(
                                    "B"
                                  )} to ${maxStorage.join("B")}`}</>
                                ) : (
                                  <>{minStorage.join("B")}</>
                                )}
                              </>
                            )}
                          </Text>
                        </TableTD>
                        <TableTD>
                          <div className="flex items-center">
                            <Text className="text-xs font-medium">
                              €
                              {filteredAddons.length > 0
                                ? getDBSPriceAmout(
                                    filteredAddons[0].pricings[0].price
                                  )
                                : "0"}{" "}
                              ex. VAT
                            </Text>
                            <Text className="text-[0.6rem]">
                              (€
                              {filteredAddons.length > 0
                                ? getDBSPriceAmout(
                                    filteredAddons[0].pricings[0].price +
                                      filteredAddons[0].pricings[0].tax
                                  )
                                : "0"}{" "}
                              incl. VAT)
                            </Text>
                          </div>
                        </TableTD>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )} */}
          </div>
          <div className="flex items-center gap-3 mt-5">
            <Button
              onClick={() => {
                if (service)
                  navigate(
                    DataServiceGeneralInformationUrl(service.engine, service.id)
                  );
              }}
            >
              Cancel
            </Button>
            <Button
              loading={
                actionLoading ||
                capabilitiesLoading ||
                availabilityLoading ||
                catalogLoading
              }
              disabled={selectedPlan === service?.plan}
              uiType="primary"
              type="submit"
            >
              Upgrade my node plan
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DBSUpgradePlanTab;
