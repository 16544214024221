import { useState } from "react";
import { Button, Card, Typography } from "djuno-design";
import { DeleteModal } from "../modals/QuestionModal";

interface DeactivatePlanProps {
  deactivateFunction: () => void;
}

const DeactivatePlan: React.FC<DeactivatePlanProps> = ({
  deactivateFunction,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeactivate = () => {
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    setIsModalOpen(false);
    deactivateFunction();
  };

  return (
    <>
      <Card title="Danger Zone" className="mt-10">
        <div className="flex flex-col gap-2 mt-4">
          <Typography.Text size="sm" className="text-sm">
            Deactivating your account will permanently delete all files and
            settings. Since you don't have a backup, you may lose important
            data. This action is irreversible. Please proceed with caution.
          </Typography.Text>
        </div>
        <div className="mt-4">
          <Button onClick={handleDeactivate} uiType="dangerLight">
            Deactivate
          </Button>
        </div>
      </Card>

      <DeleteModal
        title="Deactivate"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
        // loading={actionLoading}
        description="By deactivating this subscription, all files and settings will be deleted permanently. Please make sure you have a backup of your settings and files."
        confirmButtonText="Yes, Deactivate it!"
        confirmButtonType="danger"
        confirmString="deactivate"
      />
    </>
  );
};

export default DeactivatePlan;
